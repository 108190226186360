.login-background {
	// background: linear-gradient(-120deg, #126efa,#fc3d38,#126efa );
	// background-size: 400% 400%;
	// background-size: 100%;
	// animation: gradient 15s ease-in-out infinite alternate;
	background-color: #fc3d38;
	// animation-name: gradientBG;
	// animation-duration: 15s ease-in-out infinite;

	animation: gradientBG 7s ease-in-out infinite;
	height: 100vh !important;
	width: 100vw;
	// position: fixed;
	// top: 0;
	// bottom:0;

    .login-card{
        width: 550px;
        background: #fbfffd;
        box-shadow: 0px 3px 13px 1px rgb(255 255 255 / 53%);
        border-radius: 10px;
        text-align: center;
    }

    .imj-login-card{
        width: 350px;
    }
}

@keyframes gradientBG {
	0%{
		background: #874afa75;
	} 33% {
		background:  #d1178775;
	} 66% {
		background: #fcb23b90;
	} 100%{
		background: #874afa75;
	}
}
// @keyframes gradientBG {
// 	0%{
// 		background: #fc3d38;
// 	} 50% {
// 		background:  #126efa;
// 	} 100% {
// 		background: #fc3d38;
// 	}
// }
// @keyframes gradient {
// 	0% {
// 		background-position: 0% 50%;
// 	}
// 	50% {
// 		background-position: 100% 50%;
// 	}
// 	100% {
// 		background-position: 0% 50%;
// 	}
// }



section{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow-x: hidden;

	.imj-h2{
		color: #00000075;
	}

	.imj-login-bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.imj-login-arbol{
		position: absolute;
		top: 0;
		right: 0;
		width: 47%;
		height: 100%;
		object-fit: cover;
		z-index: 100;
		pointer-events: none;
	}

	.imj-login-bicicleta{
		position: absolute;
		scale: 0.15;
		animation: animatedBicicleta 15s linear infinite;
	}

	.p-inputtext{
		background: transparent;
	}

	.p-inputtext:enabled:hover {
		border-color: #ffffff;
	}

	.p-inputtext:hover{
		border-color: #fff;
		box-shadow: 0 0 0 0.2rem #fff;
	}

	.p-inputtext:enabled:focus {
		box-shadow: 0 0 0 0.2rem #fff;
	}

	.p-inputtext.p-invalid.p-component{
		border-color: red;
		color: red;
	}

	// .p-inputtext:enabled:focus {
	// 	outline: 0 none;
	// 	outline-offset: 0;
	// 	box-shadow: 0 0 0 0.2rem #ff7070;
	// }

	.imj-label{
		color: #fff;
	}

	.leaves{
		position: absolute;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		pointer-events: none;
	}
	
	.leaves .set{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		pointer-events: none;
	}
	
	.leaves .set div{
		position: absolute;
		display: block;
	}
	
	.leaves .set div:nth-child(1){
		left: 20%;
		animation: animatedHoja 20s linear infinite;
	}
	
	.leaves .set div:nth-child(2){
		left: 50%;
		animation: animatedHoja 12s linear infinite;
	}
	
	.leaves .set div:nth-child(3){
		left: 70%;
		animation: animatedHoja 18s linear infinite;
	}
	
	.leaves .set div:nth-child(4){
		left: 5%;
		animation: animatedHoja 15s linear infinite;
	}
	
	.leaves .set div:nth-child(5){
		left: 85%;
		animation: animatedHoja 20s linear infinite;
	}
	
	.leaves .set div:nth-child(6){
		left: 15%;
		animation: animatedHoja 12s linear infinite;
	}

	.imj-login{
		position: relative;
		background: rgba(255,255,255,0.25);
		backdrop-filter: blur(15px);
		border: 1px solid #ffffff;
		border-bottom: 1px solid rgba(255,255,255,0.5);
		border-right: 1px solid rgba(255,255,255,0.5);
		border-radius: 20px;
		width: 350px;
		display: flex;
		flex-direction: column;
		z-index: 9999;
	}
}  

@keyframes animatedBicicleta{
	0%{
		transform: translateX(calc(100% + 500vw));
	}
	50%{
		transform: translateX(calc(-500% - 100vw));
	}
	50.01%{
		transform: translateX(calc(-500% - 100vw))
		rotateY(180deg);
	}
	100%{
		transform: translateX(calc(100% + 500vw))
		rotateY(180deg);
	}
}

@keyframes animatedHoja{
	0%{
		opacity: 0;
		top: -10%;
		transform: translateX(20px) rotate(0deg);

	}
	10%{
		opacity: 1;
	}
	20%{
		transform: translateX(-20px) rotate(45deg);
	}
	40%{
		transform: translateX(-20px) rotate(90deg);
	}
	60%{
		transform: translateX(20px) rotate(180deg);
	}
	80%{
		transform: translateX(-20px) rotate(45deg);
	}
	100%{
		top: 110%;
		transform: translateX(20px) rotate(225deg);
	}
}


