// Vista Mes
.fc-dayGridMonth-view{
    .fc-timegrid-event-harness{
        margin: 1px 0;
    }
    .fc-timegrid-event .fc-event-main {
        padding: 0px 0px 0;
    }

    .fc-event-today{
        box-shadow: -7px 7px 20px #dedede !important;
    }
    .imj-card-section1{
        justify-content: start !important;
    }

    .card-week-and-day-title{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

// Vista Semana
.fc-timeGridWeek-view{
    .fc-timegrid-event-harness{
        margin: 1px 0;
        margin-right: 10px;
    }
    .fc-timegrid-event .fc-event-main {
        padding: 0px 0px 0;
    }

    .fc-event-today{
        box-shadow: -7px 7px 20px #dedede !important;
    }

    .card-week-and-day-time{
        //width: 100%;
        white-space: nowrap;
    }

    .card-week-and-day-title{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

// Vista Dia
.fc-timeGridDay-view{
    .fc-timegrid-event-harness{
        margin: 1px 0;
    }
    .fc-timegrid-event .fc-event-main {
        padding: 0px 0px 0;
    }

    .fc-event-today{
        box-shadow: -7px 7px 20px #dedede !important;
    }

    .card-week-and-day-time{
        white-space: normal;
    }

    .card-week-and-day-title{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}


.imj-card{
    color: black;

    .imj-card-area-background{
        width: 1vw !important;
    }

    .imj-card-overflow{
        overflow: hidden;
    }
    
    // .imj-card-title{
    //     white-space: break-spaces;
    // }
}

.imj-card:hover{
    opacity: .7;
}

