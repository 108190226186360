@import 'primeflex/primeflex.scss';
// @import 'primeflex/themes/saga-blue.scss';
// @import 'primeflex/themes/arya-blue.scss';
// @import 'primeflex/themes/vela-blue.scss';
//@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  background-color: #1cb5b2 !important;
  // background-color: #eb1c26 !important;
  // border-color: #eb1c26 !important;
  border-color: #fff !important;
  color: #fbfffd !important;
}

button:hover{
  opacity: .8;
}

::-webkit-scrollbar {
  width: 5px;
  height: 13px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  background-color: #dedede;
}

.imj-container{
  position: absolute;
  width: 100%;
  height: 100%;

  .imj-content{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}