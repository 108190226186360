.content-calendar{
    padding: 20px;
    // border: 3px solid #000;
    box-shadow:7px 7px 20px #dedede;
    border-radius: 20px;
    height: 100%;
  
    .fc.fc-theme-standard .fc-view-harness th{
      background: transparent !important;
    }
    thead {
      //background-color: #eb1c26;
      color: #fbfffd;
      text-transform: uppercase;

      .imj-color-title{
        color: #1cb5b2 !important;
      }

    }
  
    tbody{
      .fc-daygrid-day-top{
        a{
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          padding: 10px;
          width: 40px;
          text-align: center; 
        }
      }
  
      .fc-daygrid-day-events{
        padding: 0 10px 10px 10px;
  
        .fc-event-title{
          text-overflow: ellipsis;
        }
      }
      
      .fc-daygrid-bg-harness{
        .fc-highlight{
          background: none;
        }
      }
    }
  
    .fc .fc-popover {
      z-index: 100;
    }
}

.imj-calendario-de-ocupacion{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.p-blockui-container{
    height: 100%;
}

.fc-col-header-cell .fc-day .fc-day-wed{
  background: transparent !important;
}
// .fc-timegrid-slot{
//     height: 3.2em !important;
// }